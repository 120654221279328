import { useState, useEffect } from 'react'
import { LogoBox } from '../../components/Logo/Logo'
import { CopyrightBox } from '../../components/Footer/CopyrightBox/CopyrightBox'
import { ResetPasswordBox } from '../../components/ResetPasswordBox/ResetPasswordBox'
import { PasswordRecoveryBox } from '../../components/PasswordRecoveryBox/PasswordRecoveryBox'
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import "./RegistrationPage.scss"

const PasswordRecoveryPage = () => {
    const path = useParams();
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    
    useEffect(() => {
        if(path['*'] === ''){
            navigate('email')
        }
    },[])
    
    return (
        <div className='autorization__content'>
            <header className='autorization__header'>
                < LogoBox />
                <div className='right-header-box'>
                    <Link to="/signup" className='blue'>Регистрация</Link>
                    < Link to="/auth" className='link-box-btn'>
                        < button className='btn__blue'>Войти</button>
                    </Link>
                </div>          
            </header>
             <Routes>
                <Route path='email' 
                    element={
                        < PasswordRecoveryBox
                            title="Восстановление пароля"
                            passwordRecovery={true}
                            btn="Восстановить пароль"
                            email={email}
                            setEmail={e => setEmail(e)}
                        /> 
                    } 
                />
                <Route path=':id' 
                    element={< ResetPasswordBox email={email}/>} 
                />
            </Routes> 
            
        </div>

    )
}

export { PasswordRecoveryPage }