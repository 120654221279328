import { useState, useEffect, userRef } from 'react'
import { ARTICLE_TYPES } from '../../Article/ArticleElement'
import './ModalArticleAddElement.scss'

const ModalArticleAddElement = ({ onAddClick }) => {
    const [selectedType, setSelectedType] = useState(ARTICLE_TYPES.description)

    const onRadioChange = (type) => {
        setSelectedType(type)
    }

    return (
        <div className='blackout'>
            <div className='modal-article-add-element'>
                <h3 className='title'>Добавить</h3>
                <div className='modal-article-add-element__content'>
                    <div className='checkbox-item-box'>
                        <input checked={selectedType === ARTICLE_TYPES.description} onChange={() => onRadioChange(ARTICLE_TYPES.description)} className='radio' type='radio' radioGroup='article' />
                        <p className='text'>Текст <span className='text_grey'>(основной текст)</span></p>
                    </div>
                    <div className='checkbox-item-box'>
                        <input checked={selectedType === ARTICLE_TYPES.header} onChange={() => onRadioChange(ARTICLE_TYPES.header)} className='radio' type='radio'  radioGroup='article'/>
                        <p className='text'>Текст <span className='text_grey'>(заголовок)</span></p>
                    </div>
                    <div className='checkbox-item-box'>
                        <input checked={selectedType === ARTICLE_TYPES.image} onChange={() => onRadioChange(ARTICLE_TYPES.image)} className='radio' type='radio' radioGroup='article'/>
                        <p className='text'>Изображение <span className='text_grey'>(файл в формате jpg, jpeg, png)</span></p>
                    </div>
                    <div className='checkbox-item-box'>
                        <input checked={selectedType === ARTICLE_TYPES.warningBlock} onChange={() => onRadioChange(ARTICLE_TYPES.warningBlock)} className='radio' type='radio'  radioGroup='article'/>
                        <p className='text'>Рекомендацию <span className='text_grey'>( текст помещен в отдельный блок и выделен цветом)</span></p>
                    </div>
                </div>
                <div className='btn-box'>
                    <button onClick={() => onAddClick(selectedType)} className='btn__green'>Добавить элемент</button>
                </div>
            </div>
        </div>
    )
}

export { ModalArticleAddElement }