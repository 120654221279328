import React, { useEffect, useState, useImperativeHandle, useRef } from 'react'
import { TitleText } from './TitleText/TitleText'
import { TextArticle } from './TextArticle/TextArticle'
import { ImgArticle } from './ImgArticle/ImgArticle'
import { YellowInformationBox } from './YellowInformationBox/YellowInformationBox'
import { ModalArticleAddElement } from '../Modal/ModalArticleAddElement/ModalArticleAddElement'


export const ARTICLE_TYPES = {
    header: 'header',
    description: "description",
    warningBlock: "warningBlock",
    image: "image"
}

const componentsMap = {
    [ARTICLE_TYPES.header]: TitleText,
    [ARTICLE_TYPES.description]: TextArticle,
    [ARTICLE_TYPES.warningBlock]: YellowInformationBox,
    [ARTICLE_TYPES.image]: ImgArticle
}

const ArticleElement = React.forwardRef(({ type, data, editMode, onChange, onDelete, setEventRef, setShowAddBlockPopup }, outerRef) => {

    const ComponentToRender = componentsMap[type];
    ComponentToRender.displayName = type
    const elementRef = useRef()

    useEffect(() => {
        if (data === '') {
            elementRef.current.focus()
        }
    }, [])

    const refObject = {
        type,
        getData: () => type === ARTICLE_TYPES.image ? elementRef.current.src : elementRef.current.innerText,
        getId: () => elementRef.current.id
    }

    const onAddNewBlockClick = () => {
        setShowAddBlockPopup(true)
        setEventRef(refObject)
    }

    useImperativeHandle(outerRef, () => refObject, [])

    return (
        <>
            {/* <div className=''>
           
            */}

            <div className={editMode ? 'container-article' : 'block-wrapper'}>
                {editMode && <button className='btn__close-round' onClick={() => onDelete(refObject)}></button>}
                {/* {editMode && <button className='delete-block-button' ></button>} */}
                <ComponentToRender data={data} editMode={editMode} ref={elementRef} onChange={onChange} />
            </div>
            {/* </div> */}

            {editMode
                &&
                <div className='add-new-block'>
                    <div className='btn-box'>
                        <button onClick={() => onAddNewBlockClick()} className='btn__round-blue'></button>
                    </div>
                
                    {/* <div className={'popup' + (showAddBlockPopup ? '__visible' : '')}>
                        {Object.entries(articleElementTitlesMap).map(([key, value]) => {
                            return <button className='option' key={key} blocktype={value} onClick={e => {
                                console.log(e)
                               onAddNewBlockClick(e) 
                            } }>{key}</button>
                        })}
                    </div> */}
                </div>}
        </>
    )
})

export default ArticleElement