import React, { forwardRef, useId } from 'react'
import "./TitleText.scss"

const MAX_LENGTH = 100

const TitleText = forwardRef(({ data, editMode, onChange }, ref) => {

    const handleInput = (e) => {
        onChange()
    }

    const id = useId()

    return (
        <h3
            id={id}
            maxLength={20}
            suppressContentEditableWarning
            ref={ref}
            className='title__medium' 
            contentEditable={editMode}
            onInput={handleInput}>
            {data}
        </h3>
    )
})

export { TitleText }