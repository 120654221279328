import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RegistrationPageAdmin } from './pages/RegistrationPageAdmin/RegistrationPageAdmin';
import { AuthorizationPageAdmin } from './pages/AuthorizationPageAdmin/AuthorizationPageAdmin';
import { PasswordRecoveryPage } from './pages/PasswordRecoveryPage/PasswordRecoveryPage';
import App from './App';
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/signup/*' element={< RegistrationPageAdmin />} />
      <Route path='/auth/*' element={< AuthorizationPageAdmin />} />
      <Route path='/reset-password/*' element={< PasswordRecoveryPage />} />
      {/* <Route path='/auth/*' element={< QuestionnaireAfterRegistration />} />  */}

      <Route path='/*' element={< App />} />
    </Routes>
  </BrowserRouter>
);

