import { useState, useEffect } from 'react'
import './Checkbox.scss'

const Checkbox = ({
    id,
    value,
    onChange,
    containerClassName,
    inputClassName,
    className,
    children,
    uncontrolled
}) => {
    const [_value, set_value] = useState(value)

    const _onChange = (e) => {
        if (uncontrolled) {
            set_value(prev => !prev)
        }
        if (onChange) {
            onChange(e)
        }
    }

    useEffect(() => {
        set_value(value)
    }, [value])

    return (
        <label onClick={e => e.stopPropagation()} className={"checkbox-container " + (containerClassName ? containerClassName : "")}>
            <input
                type="checkbox"
                id={id}
                className={"checkbox " + (inputClassName ? inputClassName : "" )}
                checked={_value}
                onChange={_onChange}
            />
            <span className={"checkbox-element " + (className ? className : "")}></span>
            {children}
        </label>
    )
}

export default Checkbox