import React, { forwardRef, useRef, useState } from 'react'

const ImgArticle = forwardRef(({ data, editMode, onChange }, ref) => {
    const [uploadedImage, setUploadedImage] = useState()
    const fileInputRef = useRef()
    const showPlaceholder = (data === undefined || data === '') && uploadedImage === undefined

    const handleImageClick = (e) => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    const handleFileInput = (e) => {
        if (e.target.files.length === 0) { 
            e.preventDefault()
            return
        }
        setUploadedImage(URL.createObjectURL(e.target.files[0]))
        onChange()
    }

    return (
        <div className='article-template__img-container'>
            
            <button className='article-template__img-container__button' disabled={!editMode} onClick={handleImageClick}>
                { showPlaceholder && <div className='article-template__img-container__button__placeholder'></div>}
<img
                    ref={ref}
                    src={uploadedImage ?? data}
                    className="article-template__img-container__img"
                />

            </button>
            <input
                name='uploadedImage'
                ref={fileInputRef} 
                className='article-template__img-container__file-input'
                type='file'
                onChange={handleFileInput}
                accept='image/png, image/jpeg'
            />
        </div>
        )
})

export { ImgArticle }