import React, { useState, useEffect, forwardRef } from 'react'
import Select from 'react-select'

const DropDownSelector = forwardRef(({
    selector_title,
    options_prop,
    setState,
    placeholder,
    className = '',
    style,
    multi,
    name,
    isSearchable = true,
    defaultValue = false,
    showIndicator = true,
    icon = false,
    id,
    ...props
}, selectRef) => {
    const [value, setValue] = useState(defaultValue ? [defaultValue] : [])
    const [options, setOptions] = useState([])

    const onChange = (option, { action }) => {
        if (action === 'clear') {
            setValue(multi ? [] : '')
        } else {
            setValue(option)
        }
    }

    const dot = () => ({
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',

        ':before': {
            backgroundImage: `url(${icon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            content: '" "',
            display: 'block',
            marginRight: 5,
            height: 12,
            minWidth: 12,
        },
    });

    const CustomOption = ({ innerProps, isDisabled, label, value, isSelected}) =>
        !isDisabled ? (
            <div {...innerProps} style={{ padding: '8px 20px' }}>
                <label className="checkbox-container authorization-checkbox__container">
                    <input type="checkbox" class="checkbox"  checked={isSelected} onChange={() => null} />
                    <span className="checkbox-element "></span>
                    <div className="text-and-icon-box">
                        <p className="text_icon">{label}</p>
                        <p className='text_grey'>{value}</p>
                    </div>
                </label>
            </div>
        ) : null;

        const DefaulOption = ({ innerProps, isDisabled, label, value, isSelected}) =>
        !isDisabled ? (
            <div {...innerProps} className='option-item' style={{ padding: '8px 20px' }}>
                  <p className="text_icon">{label}</p>
            </div>
        ) : null;

    const checkStyle = id === 'check' ? {
       
    } : null


    useEffect(() => {
        multi ?
            setState([...value])
            :
            setState(value)
    }, [value])

    useEffect(() => {
        setOptions(options_prop)
    }, [options_prop])


    return (
        <div
            className={`selector_wrapper ${className}`}
            style={style ? style : {}}
        >
            <span className='selector_title'>
                {selector_title}
            </span>
            <Select
                {...{ options, value, onChange }}
                ref={selectRef}
                className='dropdown_selector'
                placeholder={placeholder ? placeholder : ''}
                defaultValue={defaultValue}
                isMulti={multi}
                isClearable
                name={name}
                isSearchable={isSearchable}
               components={{ Option: id === 'check' ? CustomOption : DefaulOption }}


                styles={{
                    menuList: (base) => ({
                        ...base, 
                        minWidth: '100px',
                        zIndex: 5000,
                        left: icon ? 'calc(50% - 50px)' : 0,
                        backgroundColor: 'white',
                        borderRadius: id === 'check' ? '0 0 16px 16px': 'none',
                        marginTop: id ===  'check' ? '-20px': 'none',
                        border: id ===  'check' ? '1px solid #F0F0F0': 'none',
                        borderTop: id ===  'check' ? 'none': 'none',
                        boxShadow: id ===  'check' ?'2px 8px 16px rgba(0, 0, 0, 0.15)' : '0px 8px 16px rgba(0, 0, 0, 0.25)',
                    }),


                    singleValue: (base) => ({
                        ...base,

                    }),
                    option: (base) => ({
                        ...base,
                        zIndex: 2000,

                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                        display: showIndicator ? 'flex' : 'none',
                    }),


                    option: (styles, { isDisabled, isFocused, isSelected }) => {
                        return {
                            ...styles,
                            backgroundColor: isSelected || isFocused && 'white',
                            color: isSelected && 'black'
                        };
                    },

                    input: icon ? (styles) => ({ ...styles, ...dot() }) : null,
                    placeholder: icon ? (styles) => ({ ...styles, ...dot('#ccc') }) : null,
                    singleValue: icon ? (styles, { data }) => ({ ...styles, ...dot(data.color) }) : null,
                }}
                {...props}
            />
        </div>
    )
})

export { DropDownSelector }