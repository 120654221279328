import { useState } from 'react'
import { Link } from 'react-router-dom'
import { LogoBox } from '../../components/Logo/Logo'
import { CopyrightBox } from '../../components/Footer/CopyrightBox/CopyrightBox'
import { AuthorizationBox } from '../../components/AuthorizationBox/AuthorizationBox'
import bannerLoader from './img/banner-loader.svg'
import bannerBottom from './img/banner-bottom.svg'
import banner from './img/banner.svg'
import bannerExecutor from './img/banner-executor.svg'
import { BarLoader } from 'react-spinners';
import "./AuthorizationPageAdmin.scss"

const AuthorizationPageAdmin = ({executor = false}) => {
    const [loading, setLoading] = useState(false)
    return (
        <div className='autorization__content'>
            {
                !loading ?
                    <div className='admin-autorization-box'>
                        <header className='admin-registration-box__header'>
                            < LogoBox />
                            <h3 className='title'>ADMIN</h3>
                        </header>
                        <div className='img-and-registation'>
                            <div className='img-and-title'>
                                <h2 className='title'>{executor ? 'ИСПОЛНИТЕЛЬ ЗАДАЧ' : 'РЕДАКТОР СТАТЕЙ'}</h2>
                                <img src={executor ? bannerExecutor : banner} className='banner-img' />
                            </div>
                            < AuthorizationBox admin={true} setLoading={e => setLoading(e)} />
                        </div>
                    </div>
                    :
                    <div className="main-loader-box">
                        <div className='main-loader-box__content'>
                            <img src={bannerLoader} />
                            <div className='progress'>
                                < BarLoader
                                    color="#27BC62"
                                    height='24'
                                    width='480'
                                    speedMultiplier={1} />
                            </div>
                            <h1 className='title'>Загружаем данные</h1>
                        </div>
                    </div>
            }

        </div>

    )
}

export { AuthorizationPageAdmin }