import { useState, useRef, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
const Calendar = require('./img/calendar.png')



const SingleDatePicker = ({
   style,
   range,
   setDate,
   date,
   ...props
}) => {

    const datePickerRef = useRef(null)
   
    return (
        <div onClick={() => datePickerRef.current.setFocus()} className='date-picker' style={style ? style : {}}>
            <DatePicker
                ref={datePickerRef}
                selected={date}
                dateFormat='dd.MM.yyyy'
                onChange={(date) => setDate(date)}
                {...props}
            />
            <img
                src={Calendar}
                alt="calendar-pic"
            />
        </div>
    );
};

export { SingleDatePicker }