import { useState, useEffect, createContext } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import Footer from './components/Footer';
import { Sidebar } from './components/Sidebar/Sidebar';
import { HeaderMain } from './components/HeaderMain/HeaderMain';
import { KnowledgeServer } from './pages/KnowledgeServer/KnowledgeServer';
import { Main } from './pages/Main/Main';
import Cookies from 'universal-cookie'
import './index.css'
import './index.scss'

function App() {
  const path = useParams()
  const navigate = useNavigate()
  const [activeSubItem, setActiveSubItem] = useState('');
  const [showNotificationBox, setShowNotificationBox] = useState(false)

  const setNavigation = async () => {
    const cookies = new Cookies()

    const isAccount = localStorage.getItem('not_one_account')
    const client_id = localStorage.getItem('client_id')
    const auth_token = await cookies.get('auth')
    if (auth_token !== null & client_id !== null) {
      if (isAccount) {
        if (path['*'] === '') {
          navigate('/article-min-box')
        }
      } 
    } else {
      navigate('/auth')
    }
  }

  useEffect(() => {
    setNavigation()
  }, [])

  useEffect(() => {
    if (path['*'] === '') {
      navigate('main/article-min-box')
    }
  }, [])

  return (
    <>
      <main className='main'>
        <div className='content'>
          <NotificationContext.Provider value={{
            showNotificationBox,
            setShowNotificationBox
          }}>
            <HeaderMain />
            <Routes>
              <Route path='/main/*' element={<Main />} />
            </Routes>
          </NotificationContext.Provider>
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
}
export const NotificationContext = createContext(false)

export default App;
