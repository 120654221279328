import { useState, useEffect } from 'react';
import { Mail } from './Mail/Mail';
import { Link, useNavigate } from 'react-router-dom';
import { VladimirHttps, VladimirHttp } from '../../fetchUrls';
import "./PasswordRecoveryBox.scss"

const PasswordRecoveryBox = ({
    title, email, setEmail
}) => {
    const navigate = useNavigate()
    const [inValid, setInvalid] = useState(false)
    const [incorrectEmail, setIncorrectEmail] = useState(false)
    const [activeContent, setActiveContent] = useState(false)
    const [disabledBtn, setdisabledBtn] = useState(false)

    const handlerSetEmail = (e) => {
        setIncorrectEmail(false)
        setInvalid(false)
        setEmail(e.target.value)
    }

    useEffect(() => {
        const regexEmail = /^((([0-9A-Za-z]{1}[-0-9A-z\.]{0,30}[0-9A-Za-z]?)|([0-9А-Яа-я]{1}[-0-9А-я\.]{0,30}[0-9А-Яа-я]?))@([-A-Za-z]{1,}\.){1,}[-A-Za-z]{2,})$/

        if (regexEmail.test(email)) {
            setdisabledBtn(true)
        } else {
            setdisabledBtn(false)
        }
    }, [email])

    const handlerSubmit = (e) => {
        if (email.length < 1) {
            setInvalid(true)
        }
        
        let url
        if(!window.location.hostname.match('localhost')){
            url = `${VladimirHttps}/reset_password`
        } else {
            url = `${VladimirHttp}/reset_password`
        }

        const body = JSON.stringify({
            login: email
        })

        fetch(url,
            {
                body,
                method: 'POST',
                headers: { 'Content-type': 'application/json' }
            })
            .then((response) => response.json())
            .catch(err => console.log(err))
            .then((data) => {
                if (data.message === 'Message was be sending') {
                    setActiveContent(true)
                } else {
                    setInvalid(true)
                }
            })
    }

    return (
        <form className='autorization' onSubmit={e => e.preventDefault()}>
            {
                !activeContent ?
                    <>
                        <h2 className='title'>{title}</h2>
                        <div className='input-with-btn'>
                            <input
                                onChange={e => handlerSetEmail(e)}
                                type="e-mail"
                                className='autorization__input-field'
                                placeholder='Введите адрес электронной почты указанной при регистрации'
                                value={email}
                            />
                        </div>
                        {inValid && <Link to="#" className='text text_red'>Некорректный email</Link>}
                        <button 
                            disabled={!disabledBtn}
                            className='btn__green'
                            style={{ marginTop: '32px' }}
                            onClick={e => handlerSubmit()}
                        >
                            Восстановить пароль
                        </button>
                    </>
                :
                    < Mail />
            }
        </form>
    )
}

export { PasswordRecoveryBox }