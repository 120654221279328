import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { NotificationList } from '../NotificationList/NotificationList';
import { ProfileSettings } from '../ProfileSettings/ProfileSettings';
import { NotificationContext } from '../../App'
import { ArtemNotificationsHttps, ArtemNotificationsHttp } from '../../fetchUrls';
import './HeaderMain.scss'

const HeaderMain = () => {
    const { showNotificationBox, setShowNotificationBox } = useContext(NotificationContext)

    const [profileName, setProfileName] = useState('')
    const [showNav, setShowNav] = useState(false)
    const [showProfileSettings, setProfileSettings] = useState(false)
    const path = useParams()
    const navigate = useNavigate()
    const client_id = localStorage.getItem('client_id')

    const [notifCount, setNotifCount] = useState(0)

    const FETCH_INTERVAL_MS = 30000

    const onLogOutHandler = () => {
        const cookies = new Cookies()

        cookies.remove('auth')
        cookies.remove('refresh')

        localStorage.removeItem('not_one_account')
        localStorage.removeItem('client_id')
        localStorage.removeItem('email')

        navigate('../auth')
    }

    const fetchNotificationsCount = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${ArtemNotificationsHttps}/notifs/${client_id}/unread_count`
        } else {
            url = `${ArtemNotificationsHttp}/notifs/${client_id}/unread_count`
        }


        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        const abortController = new AbortController()
        setTimeout(() => abortController.abort(), FETCH_INTERVAL_MS / 2)

        fetch(url, { method: 'GET', headers, signal: abortController.signal })
            .then(res => res.json())
            .then(json => {
                const {status, count} = json
                if(status.toLowerCase() === 'ok') {
                    setNotifCount(count)
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        let profile = localStorage.getItem('email')
        setProfileName(profile)

        if (!path['*'] === 'education/knowledge-server' || !path['*'] === 'education/article-template') {
            setShowNav(true)
        } else {
            setShowNav(false)
        }
        
        fetchNotificationsCount()

        setInterval(() => {
            fetchNotificationsCount()
        }, FETCH_INTERVAL_MS)

    }, [])

    document.querySelector('body').onclick = (e) => {
        if(e.target.getAttribute('class') === 'profile-box__btn' || e.target.getAttribute('class') === 'profile-box__user-name'){
            setProfileSettings(!showProfileSettings)
        } else {
            setProfileSettings(false)
        }

        let notifListItem = document.querySelector('notification-list')
        let notifBtnItem = document.querySelector('notifications__btn notifications')

        if(
            e.target.getAttribute('class') === 'notifications__btn notifications' || 
            e.target.getAttribute('class') === 'notification-list'
        ){
            setShowNotificationBox(!showNotificationBox)
        } else {
            setShowNotificationBox(false)
        }
    }


    return (
        <div className='main-sidebar'>
            <nav className='main-sidebar__nav'>
                <div className='main-sidebar__left-box'>
                    <input className='input-field' type='text' placeholder='Поиск...'></input>
                    {showNav ? 
                        <nav className='training-nav'>
                            <Link className='training-nav__item_active' to='#'>Обучение</Link>
                            <Link className='training-nav__item' to='#'>Курсы</Link>
                            <Link className='training-nav__item' to='#'>Вебинары</Link>
                            <Link className='training-nav__item' to='#'>Видео</Link>
                            <Link className='training-nav__item' to='#'>Ecomru</Link>
                            <Link className='training-nav__item' to='#'>Регистрация и вход</Link>
                        </nav>
                        :
                        null}
                </div>
                <div className='main-sidebar__right-box'>
                    < div className='profile-box'>
                        <p className='profile-box__user-name'>{profileName}</p>
                        <div className='profile-list__content'>
                            <button className='profile-box__btn' ></button>
                            {showProfileSettings ? 
                                <ProfileSettings
                                    onLogOutHandler={e => onLogOutHandler(e)}
                                /> 
                            : null}
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export { HeaderMain }