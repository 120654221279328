import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as Drop } from './img/drop.svg';
import './SidebarMenu.scss'

const SidebarMenu = ({ label,path, icon, subItems, active, setActive }) => {
    const [subItemsList, setSubItemsList] = useState([])
    const [openItems, setOpenItems] = useState(new Set(['Управление рекламой']));
    const urlPath = useParams()
    
    const handleOpenDropItem = () => {
        const newState = new Set(openItems);
        if (openItems.has(label)) {
            newState.delete(label);
        } else {
            newState.add(label);
        }
        setOpenItems(newState)
    }

    useEffect(() => {
        if (Array.isArray(subItems)) {
            const title = label
            setSubItemsList([
                ...subItems.map(({ label, path, black }) => { 
                    if(path === urlPath['*']){
                        setActive(label+title)
                    }
                    return(
                        <Link to={path ? path : "#"} key={label+Math.random(0,1)*1000} className={black ? 'settings__link' : 'settings__link_grey'}>
                            <li
                                key={label+Math.random(0,1)*1000}
                                onClick={() => {
                                    setActive(label + title)
                                }}
                                className={(active) === (label + title) ? 'settings__list-item_active' : 'settings__list-item'}
                                
                            >
                                {label}
                            </li>
                        </Link>
                    )}
                )
            ])
        }
    }, [active, openItems, urlPath])

    const dropClass = openItems.has(label) ? 'icon-drop_active' : 'icon-drop'
    const listClass = openItems.has(label) ? 'settings__list' : 'settings__list_hide'
    const boxClass = openItems.has(label) ? 'settings__link-box_active' : 'settings__link-box'

    return (
        <div key={label + icon+Math.round(0,1)*1000} className='settings__item'>
            <div className={boxClass} onClick={(e) => handleOpenDropItem(e)}>
                <Link to={path ? path : "#"} className='settings__link'>{label}</Link>
                    {subItems.length > 0 ? <Drop className={dropClass} /> : null}
            </div>
            <div className='settings__drop'>
                <ul className={listClass}>
                    {subItemsList}
                </ul>
            </div>
        </div>
    )
}

export { SidebarMenu }