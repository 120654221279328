import { Link } from 'react-router-dom';
import "./ArticleItemMin.scss"

const ArticleItemMin = ({ title, text, img }) => {

    return (
        <div className='article-min'>
            <div className='img-box'>
                <img src={img}></img>
            </div>
            <div className='content-box'>
                <h3 className='title'>{title}</h3>
                <p className='text'>{text}</p>
                <Link className='link__blue' to='../article-template/:articleId'>Открыть для редактирования</Link>
            </div>
        </div>
    )
}

export { ArticleItemMin }