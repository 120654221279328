import React, { useState, useEffect, useRef, Fragment } from 'react'
import Registration from './img/registration.svg'
import { Link } from 'react-router-dom'
import { SingleDatePicker } from '../SingleDatePicker/SingleDatePicker'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { RecommendationList } from './RecommendationList/RecommendationList'
import ArticleElement, { ARTICLE_TYPES } from './ArticleElement'
import { ListOfContents } from './ListOfContents/ListOfContents'
import { MoonLoader } from 'react-spinners'
import DropDownSelector from '../DropDownSelector';
import { ModalArticleAddElement } from '../Modal/ModalArticleAddElement/ModalArticleAddElement'


const ArticleView = ({ title, publicationDate, timeToRead, tags, isNew }) => {
    const [articleTitle, setArticleTitle] = useState(() => isNew ? 'Название' : title)
    const [loading, setLoading] = useState(false)
    const [showAddBlockPopup, setShowAddBlockPopup] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [contentData, setContentData] = useState([])
    const [changed, setChanged] = useState(false)
    const [contentLinks, setContentLinks] = useState([])
    const articleTitleRef = useRef()
    const [startDate, setStartDate] = useState(new Date())
    const [eventRef, setEventRef] = useState()

    const componentRefs = []

    const applyRef = element => {
        componentRefs.push(element)
    }

    const onChildElementChange = () => {
        computeContentList()
        setChanged(true)
    }

    const noContent = contentData.length == 0
        || (contentData.length === 1 && contentData[0].header === undefined && contentData[0].data.length === 0)

    const addFirstBlock = (type) => {
        const stateCopy = [...contentData]
        if (type === 'header') {
            stateCopy.push({ header: '', data: [] })
        } else {
            const newObject = { type, data: '' }
            if (stateCopy.length > 0) {
                stateCopy[stateCopy.length - 1].data.push(newObject)
            } else {
                stateCopy.push({ data: [newObject] })
            }
        }
        setContentData(stateCopy)
        setChanged(true)
    }

    const addNewBlock = (type) => {
        if (noContent) {
            addFirstBlock(type)
            return
        }
        // we have to collect changes from HTML here, otherwise all new blocks will be empty
        const stateWithChanges = getSavedArray()
        let stateCopy = [...stateWithChanges]
        if (type === 'header') {
            const newObject = { header: '', data: [] }
            const { dataIndex, headerIndex } = calculateIndex(eventRef, stateCopy)
            appendDataToNewHeader(newObject, dataIndex, headerIndex)
            stateCopy = [
                ...stateCopy.slice(0, headerIndex + 1),
                newObject,
                ...stateCopy.slice(headerIndex + 1)
            ]
        } else {
            const { dataIndex, headerIndex } = calculateIndex(eventRef, stateCopy)
            const newObject = { type, data: '' }
            if (stateCopy[headerIndex].data && stateCopy[headerIndex].data.length > 0) {
                stateCopy[headerIndex].data = [
                    ...stateCopy[headerIndex].data.slice(0, dataIndex + 1),
                    newObject,
                    ...stateCopy[headerIndex].data.slice(dataIndex + 1)
                ]
            } else {
                stateCopy[headerIndex].data = [newObject]
            }
        }
        setContentData(stateCopy)
        setChanged(true)

        function appendDataToNewHeader(headerObject, dataIndex, headerIndex) {
            const dataToAppend = stateCopy[headerIndex].data.splice(dataIndex + 1)
            headerObject.data = [...dataToAppend]
        }
    }

    const computeContentList = () => setContentLinks(componentRefs
        .filter(ref => ref.type === ARTICLE_TYPES.header)
        .map(ref => ({ linkText: ref.getData(), path: `${ref.getId()}` })))

    const deleteBlock = (ref) => {
        // we have to collect changes from HTML here, otherwise all new blocks will be empty
        const stateWithChanges = getSavedArray()
        const stateCopy = [...stateWithChanges]
        if (ref.type === ARTICLE_TYPES.header) {
            const index = stateCopy.findIndex(headerObject => headerObject.header === ref.getData())
            if (index === 0) { delete stateCopy[index].header }
            else {
                appendDataToAboveHeader(index, stateCopy)
                stateCopy.splice(index, 1)
            }

            setContentData(stateCopy)
            setChanged(true)
            return
        }

        const { dataIndex, headerIndex } = calculateIndex(ref, stateCopy)
        stateCopy[headerIndex].data.splice(dataIndex, 1)

        setContentData(stateCopy)
        setChanged(true)

        function appendDataToAboveHeader(index, stateCopy) {
            if (!stateCopy[index].data || stateCopy[index].data.length === 0) { return }

            const dataCopy = [...stateCopy[index].data]
            stateCopy[index - 1].data = [...stateCopy[index - 1].data, ...dataCopy]
        }
    }

    function calculateIndex(eventBlock, articleData) {
        const flatIndex = componentRefs.findIndex(block => block.type === eventBlock.type && block.getData() === eventBlock.getData())
        const parentHeader = componentRefs.slice(0, flatIndex + 1).findLast(block => block.type === ARTICLE_TYPES.header)
        let headerIndex = null
        if (parentHeader === undefined) {
            headerIndex = 0
        } else {
            headerIndex = articleData.findIndex(headerObject => headerObject.header === parentHeader.getData())
        }
        const dataIndex = flatIndex - componentRefs.findIndex(block => block === parentHeader) - 1
        return { dataIndex, headerIndex }
    }

    const save = () => {
        if (!changed) { return }


        console.log(getSavedArray())

        setChanged(false)
        setEditMode(false)
    }

    const getSavedArray = () => {
        const result = []
        componentRefs.forEach(element => {
            if (element.type === ARTICLE_TYPES.header) {
                result.push({
                    header: element.getData(),
                    data: []
                })
            } else {
                if (result.length > 0) {
                    result[result.length - 1].data.push({
                        type: element.type,
                        data: element.getData()
                    })
                } else {
                    result.push({
                        data: [{
                            type: element.type,
                            data: element.getData()
                        }]
                    })
                }
            }
        })
        return result
    }

    useEffect(() => {
        if (isNew) { return }
        setLoading(true)
        emulateResponse().then(response => {
            setLoading(false)
            setContentData(response)
        })
    }, [])

    useEffect(() => {
        if (!isNew) { return }
        setContentData([
            {
                header: '',
                data: [
                    { type: ARTICLE_TYPES.description, data: '' }
                ]
            }
        ])
        setArticleTitle('Название')
        setEditMode(true)
        setChanged(true)
    }, [isNew])

    useEffect(() => {
        computeContentList()
    }, [contentData])

    const recommendationList = [
        {
            title: "Что лучше продавать на маркетплейсах в 2023 году"
        },
        {
            title: "Как заработать на маркетплейсах в 2023 году: 10 проверенных стратегий"
        },
        {
            title: "Дропшиппинг на маркетплейсах- стоит ли? Ответ читайте здесь"
        },
        {
            title: "Сколько стоит перенести магазин с Wildberries на Ozon? Подводные камни"
        }
    ]

    function emulateResponse() {
        return new Promise((res, rej) => {
            setTimeout(() => res([
                {
                    header: 'Клиент',
                    data: [
                        {
                            type: ARTICLE_TYPES.description,
                            data: 'Unicom — это CPA-сеть партнерских программ банков, микрофинансовых организаций, страховых и юридических компаний. 200+ рекламодателей финансовой сферы России и Казахстана: ВТБ, Тинькофф, Альфа-Банк, Совкомбанк, Росбанк, Банк Восточный, Райффайзенбанк, Хоум Кредит Банк, СКБ Банк и другие.'
                        },
                        {
                            type: ARTICLE_TYPES.description,
                            data: 'Компания работает в сегментах b2c и b2b. В кейсе мы рассматриваем b2b-направление.'
                        },
                        {
                            type: ARTICLE_TYPES.description,
                            data: 'Трафик: 7000 (52% — мобильный трафик; 48% — десктоп) \nРассматриваемый сегмент: b2b \nКоличество новых регистраций в месяц: 600 \nКаналы привлечения веб-мастеров: SEO, интернет-реклама \nИнтеграции: CRM, WhatsApp, ВКонтакте и Telegram.'
                        },
                        {
                            type: ARTICLE_TYPES.image,
                            data: Registration
                        }
                    ]
                },
                {
                    header: 'Задача — быстрее активировать пользователей после регистрации',
                    data: [
                        {
                            type: ARTICLE_TYPES.description,
                            data: 'Выручка Unicom напрямую зависит от активности веб-мастеров, которые работают на платформе. Команда маркетинга искала способы активировать больше пользователей после регистрации, и стимулировать веб-мастеров быстрее делать первое размещение. Для этих задач Unicom выбрали и подключили.'
                        }
                    ]
                },
                {
                    header: 'Решение - запустили чат бота для онбординга пользователей',
                    data: [
                        {
                            type: ARTICLE_TYPES.warningBlock,
                            data: 'Если вы уже зарегистрированы как пользователь в качестве предпринимателя, то вы можете пропустить первые шаги и сразу приступить к настройкам, заполнив юридические данные вашей организации'
                        }
                    ]
                },
                {
                    header: 'Результаты обучающего чат - бота',
                    data: [
                        {
                            type: ARTICLE_TYPES.description,
                            data: 'Путь клиента Unicom выглядит так:\n1. Пользователь регистрируется на сайте.\n2. В личном кабинете веб-мастеру доступны актуальные офферы банков и финансовых организаций.\n3. Веб-мастер размещает рекламу с реферальными ссылками от партнеров на сторонних площадках. По этим ссылкам в финансовые организации приходят заявки.\n4. За каждую состоявшуюся сделку, которая пришла банку из рекламы, веб-мастер получает оплату, а Unicom — свою часть в виде комиссии.\n5. Выручка компании напрямую зависит от того, насколько веб-мастера активно размещают офферы. Заявки с реферальных ссылок — ключевая метрика Unicom.'
                        }
                    ]
                },
                {
                    header: 'Чат-боты рассказывают о новых офферх и преимуществах'
                },
                {
                    header: 'Общие результаты по всем инструментам'
                },
                {
                    header: 'Планы на будущее'
                }
            ]), 1000)
        })
    }

    return (
        <div className='article-template__content'>
            {showAddBlockPopup ? <ModalArticleAddElement onAddClick={(type) => {
                setShowAddBlockPopup(false)
                addNewBlock(type)
            }}/> : null}

             <div className='article-template__content-article'>

                <Breadcrumb
                    page='Создание статьи'
                    prev='Статьи'
                    pathPrev='/education/knowledge-server'
                    prevprev='Главная'
                />

                <div className='toolbar-filter'>
                    <div className='select-group'>
                        <DropDownSelector
                            options_prop={[]}
                            setState={e => (e)}
                            placeholder='Выбрать портал'
                        />
                        <DropDownSelector
                            options_prop={[]}
                            setState={e => (e)}
                            placeholder='Раздел'
                        />
                    </div>
                    <button className='btn__green'>Опубликовать</button>
                </div>

                <div className='title-box'>
                    <h2
                        className='title__big'
                        ref={articleTitleRef}
                        suppressContentEditableWarning
                        contentEditable={editMode}
                        onInput={(e) => {
                            setArticleTitle(e.target.text)
                            setChanged(true)
                        }}
                    >{articleTitle}</h2> 
                    { editMode && <button onClick={() => articleTitleRef.current.focus()} className='btn__edit-light'></button> }
                    { editMode && <button onClick={() => setArticleTitle("")} className='btn__delete-light'></button> }
                </div>
                <div className='line'></div>


                <div className='toolbar-info-box'>
                    <div className='toolbar-top'>
                        <div className='select-box'>
                            <p className='text_grey'>Дата публикации</p>
                            <SingleDatePicker
                                date={startDate}
                                setDate={(e) => setStartDate(e)}
                            />
                        </div>
                        <div className='tag-search-box'>
                            <div className='tag-search-box__content'>
                                <p className='text_grey'>Теги</p>
                                <input className='input-field-text' placeholder='Введите тег'></input>
                            </div>
                            <button className='btn__bej'>Добавить тег</button>
                        </div>
                    </div>
                    <div className='toolbar-bottom'>
                        {!isNew && tags.map((tag, index) => <Link key={index} to="#" >
                            <div className='tag-box_green'>
                                <p className='text_grey'>{tag}</p>
                                <button className='btn__close-round'></button>
                            </div>
                        </Link>)}

                    </div>
                </div>

                {/* <div className='article-template__info-box'>
                    <p className='text__grey'>Дата публикации <time>{isNew ? new Date().toDateString() : publicationDate}</time></p>
                    <p className='text__grey'>Время для чтения: <span>{isNew ? "" : timeToRead}</span></p>
                    <div className='tags-box'>
                        <p className='text__grey'>Теги:</p>
                        <div className='blue-box'>
                            {!isNew && tags.map((tag, index) => <Link key={index} to="#" className='text__grey'>{tag}</Link>)}
                        </div>
                    </div>
                </div> */}





                {
                    loading ?
                        <div className="loader_wrapper product_list" >
                            <MoonLoader
                                color="#7b7b7b"
                                speedMultiplier={0.5}
                            />
                        </div>
                        :
                        
                        contentData.map((headerElement, index) => {
                            return (
                                <Fragment key={index}>
                                    {typeof headerElement.header !== 'undefined'
                                        &&
                                        <ArticleElement
                                            setShowAddBlockPopup={(e) => setShowAddBlockPopup(e)}
                                            setEventRef={setEventRef}
                                            type={ARTICLE_TYPES.header}
                                            data={headerElement.header}
                                            editMode={editMode}
                                            ref={applyRef}
                                            onChange={onChildElementChange}
                                            onDelete={(e) => deleteBlock(e)}
                                        />}
                                    {headerElement.data
                                        &&
                                        headerElement.data.map((el, innerIndex) =>
                                            <ArticleElement
                                                setShowAddBlockPopup={(e) => setShowAddBlockPopup(e)}
                                                setEventRef={setEventRef}
                                                key={innerIndex}
                                                type={el.type}
                                                data={el.data}
                                                editMode={editMode}
                                                ref={applyRef}
                                                onChange={onChildElementChange}
                                                onDelete={(e) => deleteBlock(e)}
                                            />)}
                                </Fragment>
                            )
                        })}



                {/* {editMode && contentData.length === 0
                    &&
                    <div className='add-new-block'>
                        <button onClick={() => setShowAddBlockPopup(prev => !prev)} className='add-new-block__button'>+</button>
                        <div className={'popup' + (showAddBlockPopup ? '__visible' : '')}>
                            {Object.entries(articleElementTitlesMap).map(([key, value]) => {
                                return <button className='option' key={key} blocktype={value} onClick={e => addFirstBlock(e)}>{key}</button>
                            })
                            }
                        </div>
                    </div>} */}

                {/* {isNew &&
                    <div className='add-text-box'>
                        
                        <div className='textarea-box'>
                            <button className='btn__close-round'></button>
                            <textarea className='textarea__add-text' placeholder='Добавить текст' />
                        </div>
                    </div>
                } */}

                <div className='toolbar-group-btn'>
                    <div className='btn-item-box'></div>
                    <div className='btn-item-box'>
                        { 
                            isNew && noContent
                            && <button className='btn__round-blue'onClick={(e) => setShowAddBlockPopup(true)} />
                        }
                    </div>
                    <div className='btn-item-box'>
                        <button className='btn__green' onClick={save} disabled={!changed}>Сохранить статью</button>
                    </div>
                </div>



                {/* {!isNew && <RecommendationList recommendationList={recommendationList} />} */}

                <div className='article-buttons'>
                    {!isNew && <button className='btn__grey' onClick={() => setEditMode(prev => !prev)}>{editMode ? 'Отменить редактирование' : 'Редактировать'}</button>}
                    <button className='btn__green' onClick={save} disabled={!changed}>Сохранить изменения</button>
                </div>
            </div>

            <aside className='right-aside'>
                <ListOfContents links={contentLinks} isNew={isNew} />
                {!isNew && <Link to='/article-template/newArticle'><button className='btn__green'>Новая статья</button></Link>}
            </aside>
        </div>
    )
}

// const AddNewBlockButton = ({ onAddNewBlock }) => {
//     const [showAddBlockPopup, setShowAddBlockPopup] = useState(false)

//     const onAddNewBlockClick = () => {
//         setShowAddBlockPopup(false)
//         onAddNewBlock()
//     }

//     return (
//         <div className='add-new-block'>
//             <button onClick={() => setShowAddBlockPopup(prev => !prev)} className='add-new-block__button'>+</button>
//             <div className={'popup' + (showAddBlockPopup ? '__visible' : '')}>
//                 {Object.entries(articleElementTitlesMap).map(([key, value]) => {
//                     return <button className='option' key={key} blocktype={value} onClick={onAddNewBlockClick}>{key}</button>
//                 })}
//             </div>
//         </div>
//     )
// }

export default ArticleView
