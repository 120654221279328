import { Link } from 'react-router-dom'
import { LogoBox } from '../../components/Logo/Logo'
import { CopyrightBox } from '../../components/Footer/CopyrightBox/CopyrightBox'
import RegistrationBox from '../../components/RegistrationBox/'
import banner from './img/banner.svg'
import bannerExecutor from './img/banner-executor.svg'
import "./RegistrationPageAdmin.scss"

const RegistrationPageAdmin = ({ executor = false}) => {

    return (
        <div className='admin-registration-box'>
            <div className='autorization__content'>
                <header className='admin-registration-box__header'>
                    < LogoBox />
                    <h3 className='title'>ADMIN</h3>
                </header>
                <div className='img-and-registation'>
                    <div className='img-and-title'>
                        <h2 className='title'>{executor ? 'ИСПОЛНИТЕЛЬ ЗАДАЧ' : 'РЕДАКТОР СТАТЕЙ'}</h2>
                        < img src={executor ? bannerExecutor : banner} className='banner-img' />
                    </div>
                    < RegistrationBox admin={true} />
                </div>
            </div>
        </div>
    )
}

export { RegistrationPageAdmin }