import React, { forwardRef } from 'react'
import Bell from './img/bell.svg'
import "./YellowInformationBox.scss"

const YellowInformationBox = forwardRef(({ data, editMode, onChange }, ref) => {

    const handleInput = (e) => {
        onChange()
    }

    return (
        <div className='yellow-information-box'>
            <img src={Bell} className='img__bell' />
            <p
                ref={ref}
                className='text'
                suppressContentEditableWarning
                contentEditable={editMode}
                onInput={handleInput}
            >
                {data}
            </p>
        </div>

    )
})

export { YellowInformationBox }