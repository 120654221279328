import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { VladimirHttps, VladimirHttp } from '../../fetchUrls'
import Cookies from 'universal-cookie'
import ExternalAuthentication from '../ExternalAuthentication/ExternalAuthentication'
import Checkbox from '../../common/components/Checkbox'
import "./RegistrationBox.scss"

const RegistrationBox = ({ admin = false }) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [personalData, setPersonalData] = useState(false)
    const [newsSubscription, setNewsSubscription] = useState(false)
    const [hidePassword, setHidePassword] = useState(false)
    const [inValid, setInvalid] = useState(false)
    const [disabledBtn, setdisabledBtn] = useState(false)

    const cookies = new Cookies()

    const handlerSetEmail = (e) => {
        setInvalid(false)
        setEmail(e.target.value)
    }

    const handlerSetPassword = (e) => {
        setInvalid(false)
        setPassword(e.target.value)
    }

    const handlerSetName = (e) => {
        setName(e.target.value)
    }

    const handlerSetPersonalData = (e) => {
        setPersonalData(e.target.checked)
    }

    const handlerNewsSubscription = (e) => {
        setNewsSubscription(e.target.checked)
    }

    const handlerHidePassword = (e) => {
        setHidePassword(!hidePassword)
    }

    useEffect(() => {
        const regexEmail = /^((([0-9A-Za-z]{1}[-0-9A-z\.]{0,30}[0-9A-Za-z]?)|([0-9А-Яа-я]{1}[-0-9А-я\.]{0,30}[0-9А-Яа-я]?))@([-A-Za-z]{1,}\.){1,}[-A-Za-z]{2,})$/;
        //хотя бы одно число, буква латинская в нижнем регистре, латинская буква в верхнем регистре
        const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/

        if ((regexEmail.test(email)) && (regexPassword.test(password)) && personalData && name) {
            setdisabledBtn(true)
        } else {
            setdisabledBtn(false)
        }
    }, [email, password, personalData, name])

    const activeTypeIput = hidePassword ? "text" : "password"

    let form = {
        "name": name,
        "login": email,
        "password": password
    }

    // переменная для проверки
    let formBig = {
        "name": name,
        "login": email,
        "password": password,
        "personal-data": personalData,
        "news-subscription": newsSubscription
    }

    const checkToken = (refresh_token) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${VladimirHttps}/check_token`
        } else {
            url = `${VladimirHttp}/check_token`
        }

        const headers = {
            'Content-type': 'application/json'
        }

        const body = JSON.stringify({
            access_token: '',
            refresh_token
        })

        fetch(url, { method: 'POST', body, headers })
            .then(res => res.json())
            .then(json => {
                const {
                    access_token,
                    refresh_token,
                    login,
                    client_id,
                    message
                } = json

                console.log(json);

                if (access_token) {
                    cookies.set('auth', access_token, { sameSite: 'none' })
                }
                if (refresh_token) {
                    cookies.set('refresh', refresh_token, { sameSite: 'none' })
                }
                if (login) {
                    localStorage.setItem('email', login)
                }
                if (client_id) {
                    localStorage.setItem('client_id', client_id)
                }

                if (!message) {
                    navigate('../auth')
                } else {
                    throw Error(message)
                }

            })
            .catch(err => {
                console.log(err)
                navigate('../auth')
            })
    }

    const handlerSubmit = (e) => {
        e.preventDefault()
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${VladimirHttps}/add_client`
        } else {
            url = `${VladimirHttp}/add_client`
        }

        fetch(url,
            {
                method: 'POST',
                body: JSON.stringify(form),
                headers: { 'Content-type': 'application/json' }
            })
            .then((response) => response.json())
            .then((data) => {
                const { message, refresh_token } = data
                console.log(data);
                if (message == 'A User with this username exists!') {
                    setInvalid(true)
                } else {
                    localStorage.setItem("email", email)
                    cookies.set('refresh', refresh_token)
                    checkToken(refresh_token)
                }
            })
            .catch(err => {
                console.log(err)
            })

    }

    const activeClassInput = inValid ? 'autorization__input-field_err' : 'autorization__input-field'

    return (
        <form className='autorization' style={{ marginTopp: '150px' }} onSubmit={(e) => e.preventDefault()}>
            <div className='title_wrapper' style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                <h2 className='title' style={{ marginRight: '1em' }}>Регистрация</h2>
            </div>

            <ExternalAuthentication />

            <p className='text' style={{ marginTop: "16px" }}>Или через e-mail</p>

            <div className='input-with-btn'>
                <input style={{ marginTop: "16px" }}
                    onChange={(e) => handlerSetName(e)}
                    type="text"
                    className='autorization__input-field'
                    placeholder='Введите имя'
                    value={name} />
            </div>
            <div className={inValid ? 'input-with-btn_active' : 'input-with-btn'}>
                <input
                    onChange={(e) => handlerSetEmail(e)}
                    type="e-mail"
                    className={activeClassInput}
                    placeholder='Введите адрес электронной почты'
                    value={email}
                />
            </div>
            <div className='input-with-btn_password'>
                <input

                    onChange={(e) => handlerSetPassword(e)}
                    type={activeTypeIput}
                    className='autorization__input-field'
                    placeholder='Введите пароль'
                    value={password}
                />
                <button type="button" onClick={(e) => handlerHidePassword(e)} className={hidePassword ? 'hide' : 'show'}></button>
            </div>
            <div className='checkbox__box_registration'>
                <Checkbox
                    id="personal-data-checkbox"
                    value={personalData}
                    onChange={(e) => handlerSetPersonalData(e)}
                    containerClassName="authorization-checkbox__container"
                    inputClassName="authorization-checkbox"
                    className="authorization-checkbox__text"
                >
                    <span className='text'>Я даю согласие на обработку своих персональных данных согласно
                        < Link to="#" className='blue'> политике конфиденциальности</Link></span>
                </Checkbox>

                <Checkbox
                    id="subscription-checkbox"
                    value={newsSubscription}
                    onChange={(e) => handlerNewsSubscription(e)}
                    containerClassName="authorization-checkbox__container"
                    inputClassName="authorization-checkbox"
                    className="authorization-checkbox__text"
                >
                    <span className='text'>Хочу получать полезные новости и специальные предложения от Ecomru.ru</span>
                </Checkbox>
            </div>
            <div className='bottom-box-registration'>
                <button disabled={!disabledBtn} className='btn__green' onClick={e => handlerSubmit(e)} >Зарегистрироваться</button>
                {
                    admin ? <div className='right-header-box'>
                        <span className='text'>Уже есть аккаунт?</span>
                        < Link to="/auth" className='link-box-btn'>
                            < button className='btn__blue'>Войти</button>
                        </Link>
                    </div>
                        : null
                }

            </div>


        </form>
    )
}


export { RegistrationBox }