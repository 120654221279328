import React from 'react'
import { useParams } from 'react-router-dom'
import "./ArticleTemplate.scss"
import ArticleView from '../../components/Article/ArticleView'

const ArticleTemplate = ({ newArticle }) => {

    const { articleId } = useParams()

    return (
        <div className='article-template'>
            <ArticleView
                id={articleId}
                title='Зарегистрируйтесь и подтвердите аккаунт'
                publicationDate='26.12.2022'
                timeToRead='5 минут'
                tags={['Банки партнеры', 'Рекламодатели', 'Регистрация']}
                isNew={newArticle}
            />
        </div>
    )
}

export { ArticleTemplate }