
import { useState, useEffect } from 'react'
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { ArticleMinBox } from "../../components/ArticleMinBox/ArticleMinBox";
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu'
import { ArticleTemplate } from '../ArticleTemplate/ArticleTemplate';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import "./Main.scss"

const Main = () => {

    const path = useParams()
    const navigate = useNavigate()


    const [sidebarList, setSidebarList] = useState([])
    const [isActive, setIsActive] = useState('Рекламные кампании')

    const [hideMenu, setHideMenu] = useState(true)
    const dropClass = hideMenu ? 'icon-drop_active' : 'icon-drop'

    const [sidebarItems, setSidebarItems] = useState(
        [
            {
                label: 'Управление рекламой',
                subItems: [
                    {
                        label: 'Рекламные кампании',
                        path: 'article-min-box',
                        black: true
                    },
                    {
                        label: 'Добавление товаров',
                        black: true,
                        path: '',
                    },
                    {
                        label: 'Управление кампанией',
                        black: true,
                        path: ''
                    },
                    {
                        label: 'Добавление акций',
                        black: true
                    },
                ],
            },
            {
                label: 'Уведомления',
                subItems: [
                    {
                        label: 'Настроить уведомления',
                        black: true
                    },
                ],
            },
            {
                label: 'Настроить правила',
                subItems: [
                    {
                        label: 'Установка правил',
                        black: true
                    },
                    {
                        label: 'Список правил',
                        black: true
                    },
                ],
            },
        ]
    )


    useEffect(() => {
        console.log(path)
        if (path['*'] === 'article-template') {
            navigate('/:articleId')
        }
    })

    useEffect(() => {

        setSidebarList([
            ...sidebarItems.map((el, idx, { label }) => <SidebarMenu key={label + '' + idx} {...el} setActive={e => setIsActive(e)} active={isActive} />)
        ])
    }, [isActive])

    return (
        <>
            <div className='breadcrumb-box'>
                <Breadcrumb
                    prev='Главная'
                    page='Статьи'
                />
            </div>
            <div className='settings__content'>
                <div className='flex'>
                    <div className={hideMenu ? 'settings__box' : 'settings__box_active'}>
                        <nav className='settings__nav'>
                            {sidebarList}
                        </nav>
                    </div>
                    <div className='settings__main-box_white'>
                        <Routes>
                            <Route path='/article-min-box' element={< ArticleMinBox />} />
                            <Route path='article-template'>
                                <Route path=':articleId' element={<ArticleTemplate />} />
                                <Route path='newArticle' element={<ArticleTemplate newArticle={true} />} />
                            </Route>
                        </Routes>
                    </div>
                </div>
            </div>
        </>

    )
}

export { Main }