import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Mail = () => {

    return (
        <div className='check-your-mail__box'>
            <h2 className='title'>Проверьте почту и перейдите по ссылке</h2>
            <p className='text'>Мы отправили ссылку для восстановления пароля на контактную почту, указанную при регистрации</p>
            <Link to="#" className='text text_blue'>Не получили письмо?</Link>
        </div>
    )
}

export { Mail }