import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { ArticleItemMin } from '../ArticleItemMin/ArticleItemMin';
import "./ArticleMinBox.scss"
import banner1 from './img/banner1.svg'
import banner2 from './img/banner2.svg'
import banner3 from './img/banner3.svg'
import DropDownSelector from '../DropDownSelector';

const ArticleMinBox = () => {

    const [selectedValue, setSelectedValue] = useState([])

    return (
        <div className='article-min-box'>
            <div className='toolbar-top'>
                <div className='filter-group'>
                    <DropDownSelector
                        style={{ maxWidth: '200px' }}
                        options_prop={[]}
                        setState={() => { }}
                        className='connections_page_selector'
                        placeholder='Выбрать портал'
                    />

                    <div className='select-box'>
                        <p className='text'>Сортировать по</p>
                        <DropDownSelector
                            style={{ maxWidth: '200px' }}
                            options_prop={[]}
                            setState={() => { }}
                            className='connections_page_selector'
                            placeholder='Дате добавления'
                        />
                    </div>
                </div>

                <div className='btn-group'>
                    <button className='btn__green'>Добавить статью</button>
                    <button className='btn__bej'>Добавить раздел</button>
                </div>
            </div>


            <div className='article-box'>
                <ArticleItemMin
                    title='Выведение карточек товара в топ'
                    text='Доверительное управление вашим магазином съэкономит ваши силы и время и выведет ваши продажи в топ '
                    img={banner1}
                />

                <ArticleItemMin
                    title='Выведение карточек товара в топ'
                    text='Доверительное управление вашим магазином съэкономит ваши силы и время и выведет ваши продажи в топ'
                    img={banner2}
                />

                <ArticleItemMin
                    title='Выведение карточек товара в топ'
                    text='Доверительное управление вашим магазином съэкономит ваши силы и время и выведет ваши продажи в топ'
                    img={banner3}
                />

                <ArticleItemMin
                    title='Выведение карточек товара в топ'
                    text='Доверительное управление вашим магазином съэкономит ваши силы и время и выведет ваши продажи в топ'
                    img={banner1}
                />

                <ArticleItemMin
                    title='Выведение карточек товара в топ'
                    text='Доверительное управление вашим магазином съэкономит ваши силы и время и выведет ваши продажи в топ'
                    img={banner2}
                />

                <ArticleItemMin
                    title='Выведение карточек товара в топ'
                    text='Доверительное управление вашим магазином съэкономит ваши силы и время и выведет ваши продажи в топ'
                    img={banner3}
                />
            </div>
        </div>
    )
}

export { ArticleMinBox }