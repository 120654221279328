import React from 'react'
import { VladimirHttps, VladimirHttp } from '../../fetchUrls'
import google from './img/google.svg'
import yandex from './img/yandex.svg'
import facebook from './img/facebook.svg'
import * as constants from './constants'
import './ExternalAuthentication.scss'

const ExternalAuthentication = () => {
    const handlerGoogle = (e) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${VladimirHttps}/redirect_pages`
        } else {
            url = `${VladimirHttp}/redirect_pages`
        }

        localStorage.setItem(constants.AUTHENTICATION_METHOD, constants.GOOGLE)

        window.location.assign(url)
    }

    const handlerYandex = (e) => {
        let url = `https://oauth.yandex.ru/authorize?response_type=code&client_id=4c9bf5e00eac415e93bb9cb4bdf8f8f5`

        localStorage.setItem(constants.AUTHENTICATION_METHOD, constants.YANDEX)
        
        window.location.assign(url)
    }

    const handlerFacebook = (e) => {
        // to be done
        
        // let url = `https://apps1.ecomru.ru:4432/sass/facebook`

        // window.location.assign(url)
    }

    return (
        <div className='btn-group__social-media'>
            <div className='btn__social-media' onClick={() => handlerGoogle()}>
                <img src={google} />
                <p className='text'>Google</p>
            </div>

            <div className='btn__social-media' onClick={() => handlerYandex()}>
                <img src={yandex} />
                <p className='text'>Яндекс</p>
            </div>

            {/* <div className='btn__social-media' onClick={() => handlerFacebook()}>
                <img src={facebook} />
                <p className='text'>Facebook</p>
            </div> */}
        </div>
    )
}

export default ExternalAuthentication