import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { VladimirHttps, VladimirHttp } from '../../fetchUrls';
import Cookies from 'universal-cookie';
import ExternalAuthentication from '../ExternalAuthentication/ExternalAuthentication'
import Checkbox from '../../common/components/Checkbox'
import "./AuthorizationBox.scss"

const AuthorizationBox = ({ setLoading, admin = false }) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [inValid, setInvalid] = useState(true)
    const [rememberMe, setRememberMe] = useState(false)
    const [disabledBtn, setdisabledBtn] = useState(false)
    const [hidePassword, setHidePassword] = useState(false)

    const handlerSetEmail = (e) => {
        setInvalid(false)
        setEmail(e.target.value)
    }

    const handlerSetPassword = (e) => {
        setPassword(e.target.value)
    }

    const handlerSetRememberMe = (e) => {
        setRememberMe(e.target.checked)
    }

    const handlerHidePassword = (e) => {
        setHidePassword(!hidePassword)
    }

    const activeTypeInput = hidePassword ? "text" : "password"

    let form = {
        'login': email,
        'password': password
    }

    let formBig = [email, password, rememberMe]

    useEffect(() => {
        const regexEmail = /^((([0-9A-Za-z]{1}[-0-9A-z\.]{0,30}[0-9A-Za-z]?)|([0-9А-Яа-я]{1}[-0-9А-я\.]{0,30}[0-9А-Яа-я]?))@([-A-Za-z]{1,}\.){1,}[-A-Za-z]{2,})$/;
        //хотя бы одно число, буква латинская в нижнем регистре, латинская буква в верхнем регистре
        const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/

        setInvalid(false)

        if ((regexEmail.test(email)) && (regexPassword.test(password))) {
            setdisabledBtn(true)
        } else {
            setdisabledBtn(false)
        }
    }, [email, password])


    const handlerSubmit = (e) => {
        setLoading(true)
        const cookies = new Cookies()
        e.preventDefault()
        let url
        console.log(window.location.hostname);
        if (!window.location.hostname.match('localhost')) {
            url = `${VladimirHttps}/authorize`
        } else {
            url = `${VladimirHttp}/authorize`
        }

        fetch(url,
            {
                method: 'POST',
                body: JSON.stringify(form),
                headers: { 'Content-type': 'application/json' }
            })
            .then((response) => response.json())
            .then(async (json) => {
                const {
                    client_id,
                    access_token,
                    refresh_token,
                    not_one_account
                } = json
                console.log(json);

                if (json.status === 'False') {
                    setInvalid(true)
                } else {
                    let d = new Date()
                    let d2 = new Date()
                    d.setTime(d.getTime() + (1000 * 60 * 60 * 24))
                    d2.setTime(d.getTime() + (1000 * 60 * 60 * 24 * 7))
                    if (email) {
                        localStorage.setItem("email", email)
                    }
                    if (client_id) {
                        localStorage.setItem("client_id", client_id)
                    }
                    if (not_one_account !== 'undefined') {
                        localStorage.setItem('not_one_account', not_one_account)
                    }
                    if (access_token) {
                        await cookies.set('auth', access_token, { expires: d })
                    }
                    if (refresh_token) {
                        await cookies.set('refresh', refresh_token, { expires: d2 })
                    }

                    setLoading(false)
                    if (not_one_account) {
                        navigate('/auth')
                    } else {
                        navigate('../main/article-min-box')
                    }
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <form className='autorization' onSubmit={e => handlerSubmit(e)} >
            <h2 className='title'>Войти</h2>
            <ExternalAuthentication />
            <p className='text' style={{ marginTop: "16px" }}>Или через e-mail</p>
            <div className='input-with-btn'>
                <input
                    onChange={(e) => handlerSetEmail(e)}
                    type="e-mail"
                    className='autorization__input-field'
                    placeholder='Введите адрес электронной почты'
                    value={email}
                />
            </div>

            <div className='input-with-btn'>
                <input
                    onChange={e => handlerSetPassword(e)}
                    type={activeTypeInput}
                    className='autorization__input-field'
                    placeholder='Введите пароль'
                    value={password}
                />
                <button type="button" onClick={() => handlerHidePassword()} className={hidePassword ? 'hide' : 'show'}></button>
            </div>

            <div className='checkbox__box'>
                {
                    !inValid
                        ?
                        <Checkbox
                            id="checkbox"
                            containerClassName="authorization-checkbox__container"
                            inputClassName="authorization-checkbox"
                            className="authorization-checkbox__text"
                            value={rememberMe}
                            onChange={e => handlerSetRememberMe(e)}
                        >
                            <span className='text'>Запомнить меня?</span>
                        </Checkbox>
                        :
                        <span className='text text_red'>Неверный логин или пароль</span>
                }

                <Link to="/reset-password/email" className='text text_blue'>Забыли пароль?</Link>
            </div>

            <div className='bottom-box-registration'>
                <button disabled={!disabledBtn} className='btn__green'>Войти</button>
                {
                    admin ? < Link to="/signup" className='link-box-btn'>
                        < button className='btn__blue'>Регистрация</button>
                    </Link>
                        : null
                }

            </div>
        </form >
    )
}

export { AuthorizationBox }