const ListOfContents  = ({ links }) => {

    const linksList = links.map(({linkText, path}, index) => <a key={index} href={`#${path}`} className='link__blue'>{linkText}</a>)
    return (
        <div className='link-box'>
            <p className='text__blue'>Содержание:</p>
            {linksList}
        </div>
    )
}

export { ListOfContents }