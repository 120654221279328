import React, { forwardRef } from 'react'
import "./TextArticle.scss"

const TextArticle = forwardRef(({ data, editMode, onChange }, ref) => {

    const handleInput = (e) => {
        onChange()
    }

    return (
        <p className='text' suppressContentEditableWarning contentEditable={editMode} ref={ref} onInput={handleInput}>
            {data}
        </p>
    )
})

export { TextArticle }