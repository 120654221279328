import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./Breadcrumb.scss"

const Breadcrumb = ({prev, page}) => {

    return (
        <nav class="breadcrumb__nav">
            <ul class="breadcrumb">
                <li><a href="#" class="breadcrumb__link">{prev}</a></li>
                <li>{page}</li>
            </ul>
        </nav>
    )
}

export { Breadcrumb }