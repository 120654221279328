import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { VladimirHttps, VladimirHttp } from '../../fetchUrls';

const ResetPasswordBox = ({ email }) => {
    const navigate = useNavigate()
    const path = useParams()
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [hidePassword, setHidePassword] = useState(true)
    const [inValid, setInvalid] = useState(false)
    const [disabledBtn, setdisabledBtn] = useState(false)

    const handlerSetPassword = (e) => {
        setInvalid(false)
        setPassword(e.target.value)
    }

    const handlerSetRepeatPassword = (e) => {
        setInvalid(false)
        setPasswordRepeat(e.target.value)
    }

    const handlerHidePassword = (e) => {
        setHidePassword(!hidePassword)
    }

    let form = {
        "login": email,
        "new_password": password,
        "repeat_new_password": passwordRepeat,
    }

    useEffect(() => {
        const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/

        if ((regexPassword.test(password)) && (regexPassword.test(passwordRepeat))) {
            setdisabledBtn(true)
        } else {
            setdisabledBtn(false)
        }
    }, [password, passwordRepeat])

    const handlerSubmit = (e) => {
        e.preventDefault()
        if (password !== passwordRepeat) {
            setInvalid(true)
        } else {
            let url
            if(!window.location.hostname.match('localhost')){
                url = `${VladimirHttps}/new_password`
            } else {
                url = `${VladimirHttp}/new_password`
            }

            fetch(url,
            {
                method: 'POST',
                body: JSON.stringify(form),
                headers: { 'Content-type': 'application/json' }
            })
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem("email", email)
                navigate('../../education/knowledge-server')      
            })
            .catch(err => {             
                console.log(err) 
            })
        }
    }

    const activeClassPassword = inValid ? 'autorization__input-field_err' : 'autorization__input-field'
    return (
        <form className='autorization' onSubmit={e => e.preventDefault()}>
            <h2 className='title'>Установить новый пароль</h2>
            <div className='input-with-btn'>
                <input
                    onChange={(e) => handlerSetPassword(e)}
                    type={hidePassword ? 'password' : 'text'}
                    className={activeClassPassword}
                    placeholder='Введите новый пароль'
                />
                <button type="button" onClick={(e) => handlerHidePassword(e)}  className={hidePassword ?'show' : 'hide'}></button>
            </div>
            <div className='input-with-btn'>
                <input
                    onChange={(e) => handlerSetRepeatPassword(e)}
                    type={hidePassword ? 'password' : 'text'}
                    className={activeClassPassword}
                    placeholder='Введите пароль еще раз'
                />
                <button type="button" onClick={e => handlerHidePassword(e)}  className={hidePassword ?'show' : 'hide'}></button>
            </div>
            {inValid ? <Link to="#" className='text text_red'>Введеные пароли не совпадают</Link> : null} 
            <button
                onClick={e => handlerSubmit(e)}
                disabled={!disabledBtn}
                className='btn__green'
            >
                Сохранить пароль
            </button>
        </form>
    )
}

export { ResetPasswordBox }